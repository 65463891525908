import styled from "styled-components";
import { NavLinks } from "./nav-links";
import { spacing16, spacing2, spacing4, spacing6, spacing8 } from "@litbase/alexandria";
import { Download } from "@styled-icons/boxicons-regular";
import { down } from "styled-breakpoints";
import offerDoc from "../assets/docs/ajanlat.pdf?url";
import visitDoc from "../assets/docs/egyszeru_latogatas.pdf?url";
import messageDoc from "../assets/docs/uzenetkuldes.pdf?url";
import { T } from "./translate-component";

export function Footer() {
  return (
    <Body>
      <Row>
        <NavLinks />
      </Row>
      <DownloadLinks>
        <span>
          <T>downloadable</T>:
        </span>
        <A href={visitDoc} download>
          <Download />
          <span>
            <T>simpleVisit</T>
          </span>
        </A>
        <A href={messageDoc} download>
          <Download />
          <span>
            <T>messageSending</T>
          </span>
        </A>
        <A href={offerDoc} download>
          <Download />
          <span>
            <T>quoteRequest</T>
          </span>
        </A>
      </DownloadLinks>
      <Row>
        <B>2022 Polimeri T4 Services Kft.</B>
      </Row>
    </Body>
  );
}

const B = styled.b`
  color: ${({ theme }) => theme.secondaryText} !important;
`;

const A = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.secondaryText};
  font-weight: normal;
  transition: color 0.3s;
  &:hover {
    text-decoration: none;
  }
`;

const DownloadLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.secondaryText};
  padding: ${spacing8};
  svg {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.medium};

    margin-right: ${spacing2};
    margin-left: ${spacing6};
    ${down("lg")} {
      margin-left: 0;
    }
  }
  ${down("lg")} {
    flex-direction: column;
    align-items: center;
    text-align: center;
    > * + * {
      margin-top: ${spacing6};
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  > * {
    font-weight: medium;
    color: ${({ theme }) => theme.black};
    font-size: 18px;
  }
  ${down("lg")} {
    flex-direction: column;
    text-align: center;
    > * + * {
      margin-top: ${spacing4};
    }
  }
`;

const Body = styled.div`
  padding: ${spacing16};
`;
