import exo2Regular from "../assets/fonts/exo 2/static/Exo2-Regular.ttf";
import exo2Medium from "../assets/fonts/exo 2/static/Exo2-Medium.ttf";
import exo2Bold from "../assets/fonts/exo 2/static/Exo2-Bold.ttf";
import { css } from "styled-components";
import { FontWeight } from "@litbase/alexandria";
import Inter from "../assets/fonts/inter/Inter-Regular.woff2";
import InterMedium from "../assets/fonts/inter/Inter-Medium.woff2";
import InterBold from "../assets/fonts/inter/Inter-Bold.woff2";
import InterVariable from "../assets/fonts/inter/Inter_variable.ttf";
import InterSemibold from "../assets/fonts/inter/Inter-SemiBold.ttf";

export const fonts = css`
  @font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: ${FontWeight.Regular};
    src: url("${exo2Regular}") format("woff2");
  }

  @font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: ${FontWeight.Medium};
    src: url("${exo2Medium}") format("woff2");
  }

  @font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: ${FontWeight.Bold};
    src: url("${exo2Bold}") format("woff2");
  }

  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: ${FontWeight.Regular};
    src: url("${Inter}") format("woff2");
  }

  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: ${FontWeight.Medium};
    src: url("${InterMedium}") format("woff2");
  }

  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: ${FontWeight.Bold};
    src: url("${InterBold}") format("woff2");
  }

  @font-face {
    font-family: "Inter";
    src: url("${InterVariable}") format("truetype");
  }

  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: ${FontWeight.SemiBold};
    src: url("${InterSemibold}") format("truetype");
  }
`;
