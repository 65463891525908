import styled from "styled-components";
import { T, useTranslation } from "./translate-component";
import { Link } from "gatsby";
import { spacing4, spacing6 } from "@litbase/alexandria";
import Tippy from "@tippyjs/react";
import { ReactNode } from "react";
import { down, up } from "styled-breakpoints";
import { getAltLanguageLink, getLinkPath, jumpToElement } from "../links";

export function NavLinks() {
  const flagSrc = useTranslation("flag");
  return (
    <>
      <Tippy
        theme="light"
        interactive
        content={
          <Col>
            <FakeNavLink to="/#our-company">
              <T>ourCompany</T>
            </FakeNavLink>
            {/*<FakeNavLink to="/#map-block">*/}
            {/*  <T>map</T>*/}
            {/*</FakeNavLink>*/}
            <FakeNavLink to="/#gallery">
              <T>gallery</T>
            </FakeNavLink>
          </Col>
        }
      >
        <StyledLink
          to={getLinkPath("/#our-company")}
          onClick={() => {
            setTimeout(() => jumpToElement("#our-company"), 300);
          }}
        >
          <T>aboutUs</T>
        </StyledLink>
      </Tippy>
      <StyledLink to={getLinkPath("/termekek")}>
        <T>products</T>
      </StyledLink>
      <LargeScreenLink to={getLinkPath("/#contact")}>
        <T>contactQuote</T>
      </LargeScreenLink>
      <SmallScreenLink to={getLinkPath("/#contact")}>
        <T>contact</T>
      </SmallScreenLink>
      <SmallScreenLink to={getLinkPath("/#quote")}>
        <T>quote</T>
      </SmallScreenLink>
      <LanguagePicker to={getAltLanguageLink()}>
        <img src={flagSrc} width="16" height="12" />
        <T>lang</T>
      </LanguagePicker>
    </>
  );
}

const Col = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing4};
  > * + * {
    margin-top: ${spacing6};
  }
`;

function FakeNavLink({ to, children, className }: { to: string; children: ReactNode; className?: string }) {
  return (
    <StyledLink
      className={className}
      to={getLinkPath(to)}
      onClick={() => {
        // Kids, do as I say not as I do. Case in point, don't do this:
        setTimeout(() => jumpToElement(to), 300);
      }}
    >
      {children}
    </StyledLink>
  );
}

const LargeScreenLink = styled(FakeNavLink)`
  ${down("lg")} {
    display: none;
  }
`;
const SmallScreenLink = styled(FakeNavLink)`
  ${up("lg")} {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  font-size: 18px;
  color: ${({ theme }) => theme.secondaryText};
  cursor: pointer;
  padding: 0 ${spacing6};
  transition: color 0.3s;
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.black};
  }
`;

const LanguagePicker = styled(Link)`
  font-weight: bold;
  color: ${({ theme }) => theme.secondaryText};
  padding: 0 ${spacing6};
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    margin-right: ${({ theme }) => theme.spacing2};
  }
`;
