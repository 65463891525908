import { GlobalStyle, theme } from "../styles/theme-types";
import { Navbar } from "./navbar";
import { Footer } from "./footer";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { LanguageKey } from "@litbase/alexandria/models/translated-object";
import { getLanguageSettings, getTranslation, setLanguageSettings } from "../utils/translate";
import { Helmet } from "react-helmet";
import { getAltLanguageLink } from "../links";
import playgroundImage from "../assets/images/playground.png";
import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { startLitbaseClient } from "../litbase-client";
import { graphql, useStaticQuery } from "gatsby";

export function Layout({
  pageContext,
  location,
  children,
}: {
  pageContext: { language: LanguageKey };
  children: ReactNode;
}) {
  const language = pageContext.language;
  setLanguageSettings({ language });

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  if (typeof window === "undefined") {
    global.location = location;
  } else {
    startLitbaseClient();
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Helmet>
        <title>POT4 Kft.</title>
        <link rel="alternate" hrefLang={language === "hu" ? "en" : "hu"} href={getAltLanguageLink()} />
        <meta property="og:image" content={data.site.siteMetadata.siteURL + playgroundImage} />
        <meta property="og:image:width" content="2048" />
        <meta property="og:image:height" content="1363" />
        {/* Not using useTranslate to prevent an infinite render loop */}
        <meta property="og:description" content={getTranslation("heroPrimary")} />
        <meta name="description" content={getTranslation("heroPrimary")} />
      </Helmet>
      {children}
      <Navbar />
      <Footer />
    </ThemeProvider>
  );
}
