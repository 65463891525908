import { baseGlobalStyle, createTheme, FontWeight, spacing16, spacing5, spacing6, spacing8 } from "@litbase/alexandria";
import styled, { createGlobalStyle, css } from "styled-components";
import { fonts } from "./fonts";

export const breakPointsPixels = {
  xs: "400",
  sm: "576",
  md: "768",
  lg: "992",
  xl: "1200",
  xxl: "1440",
};

export const theme = createTheme({
  primary100: "hsl(263,48%,95%)",
  primary200: "hsl(261,46%,87%)",
  primary300: "hsl(262,46%,79%)",
  primary400: "hsl(262,46%,63%)",
  primary500: "hsl(262,52%,47%)",
  primary600: "hsl(262,52%,42%)",
  primary700: "hsl(262,51%,28%)",
  primary800: "hsl(261,52%,21%)",
  primary900: "hsl(262,53%,14%)",

  shadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

  black: "#0C093F",
  dark: "#150F6B",
  medium: "#4338CA",
  light: "#5046E5",
  secondaryText: "#706E91",
  white: "#F9FAFC",
  whitesmoke: "#F5F7FA",
  gray: "#EBEBEB",
  lightGray: "#C6C5DD",

  fontFamilyBase: "Inter",
});

export type ThemeInterface = typeof theme;

const commonScrollbarStyle = css`
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const darkScrollbarStyle = css`
  ${commonScrollbarStyle};

  scrollbar-color: white transparent;

  &::-webkit-scrollbar-thumb {
    background: white;
  }
`;

export const lightScrollbarStyle = css`
  ${commonScrollbarStyle};

  scrollbar-color: #888 transparent;

  &::-webkit-scrollbar-thumb {
    background: #888;
  }
`;

export const scrollbarStylesMixin = css<{ $backgroundTone?: "light" | "dark" }>`
  ${({ $backgroundTone = "light" }) => ($backgroundTone === "dark" ? darkScrollbarStyle : lightScrollbarStyle)};
`;

export const GlobalStyle = createGlobalStyle`
  ${baseGlobalStyle};
  ${fonts};

  * {
    ${scrollbarStylesMixin};
    scroll-behavior: smooth;
  }
  
  body {
    min-height: 100vh;
    font-family: ${({ theme }) => theme.fontFamilyBase};
  }

  input {
    outline: none !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.black};
    font-weight: ${FontWeight.Bold};
  }
  
  h1 {
    display: flex;
    flex-direction: column;
    width: fit-content;
    font-size: 36px;
    margin-bottom: ${spacing6};
  }
  h1:after {
    content: "";
    width: 40%;
    border-bottom: 3px solid ${({ theme }) => theme.medium};
  }
  h2 {
    &:not(:first-child){
      margin-top: ${spacing8};
    }
    font-size: 24px;
  }

  .tippy-content {
    font-size: ${({ theme }) => theme.textSm};
  }
  
  ul {
    padding-left: ${spacing5};
  }
  
  p {
    color: ${({ theme }) => theme.secondaryText};
    max-width: 550px;
    font-weight: ${FontWeight.Medium};
  }
  
  a {
    font-weight: bold;
  }
  
  section {
    > * {
      max-width: ${breakPointsPixels.xl}px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${spacing16};
    &:nth-child(2n){
      background: ${({ theme }) => theme.whitesmoke};
    }
    &:nth-child(2n+1){
      background: ${({ theme }) => theme.white};
    }
  }
`;

export const CenteredTitle = styled.h1`
  margin-left: auto;
  margin-right: auto;
  &:after {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const CenteredSubtitle = styled.span`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 18px;
  color: ${({ theme }) => theme.secondaryText};
`;
