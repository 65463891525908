import { getLanguageSettings } from "./utils/translate";

export function getLinkPath(path: string, language = getLanguageSettings().language) {
  const cleanPath = path.replace(/\/en/, "");

  if (language === "en") {
    return "/en" + cleanPath;
  }

  return cleanPath;
}

export function getAltLanguageLink() {
  const targetLanguage = getLanguageSettings().language === "hu" ? "en" : "hu";

  return getLinkPath(location.pathname + location.hash, targetLanguage);
}

export function jumpToElement(path: string) {
  const match = path.match(/#([a-zA-Z0-9-]+)/);

  if (!match) return;

  const [, p1] = match;

  document.getElementById(p1)?.scrollIntoView({ behavior: "smooth" });
}
