import { spacing3, spacing4, spacing6, useMeasure } from "@litbase/alexandria";
import styled from "styled-components";
import { Link } from "gatsby";
import { down, up } from "styled-breakpoints";
import { Menu } from "@styled-icons/boxicons-regular";
import { animated, useSpring } from "react-spring";
import { useRef, useState } from "react";
import { NavLinks } from "./nav-links";
import { getLinkPath, jumpToElement } from "../links";

export function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const to = "/#hero";
  return (
    <BodyContainer>
      <Body>
        <Title
          to={getLinkPath(to)}
          onClick={() => {
            setTimeout(() => jumpToElement(to), 300);
          }}
        >
          Polimeri T4 Services Kft.
        </Title>
        <Links>
          <NavLinks />
        </Links>
        <MenuButton onClick={() => setIsMenuOpen(!isMenuOpen)} />
      </Body>
      <MobileMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    </BodyContainer>
  );
}

function MobileMenu({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const ref = useRef<HTMLDivElement | null>(null);

  useMeasure((bounds) => {
    setHeight(bounds.height);
  }, ref);

  const [height, setHeight] = useState(0);
  const styles = useSpring({
    maxHeight: isOpen ? height : 0,
    opacity: isOpen ? 1 : 0,
    pointerEvents: isOpen ? "all" : "none",
  });

  return (
    <>
      <MenuBackground style={{ opacity: styles.opacity, pointerEvents: styles.pointerEvents }} />
      <MobileMenuBody style={{ maxHeight: styles.maxHeight }} onClick={onClose}>
        <div ref={ref}>
          <MobileMenuContents>
            <NavLinks />
          </MobileMenuContents>
        </div>
      </MobileMenuBody>
    </>
  );
}

const MenuBackground = styled(animated.div)`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.55);
  z-index: 0;
`;

const BodyContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 10;
`;

const MobileMenuContents = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing6};
  > * + * {
    margin-top: ${spacing3};
  }
`;

const MobileMenuBody = styled(animated.div)`
  overflow: hidden;
  background: white;
  z-index: 1;
`;

const MenuButton = styled(Menu)`
  margin-left: auto;
  font-size: 24px;
  cursor: pointer;
  ${up("lg")} {
    display: none;
  }
`;

const Links = styled.div`
  display: flex;
  margin-left: auto;
  ${down("lg")} {
    display: none;
  }
`;

const Title = styled(Link)`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.black};
    text-decoration: none;
  }
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${spacing4};
  box-shadow: ${({ theme }) => theme.shadow};
  background: white;
  z-index: 2;
`;
