import { FunctionComponent, memo, ReactNode, useCallback } from "react";
import { useObservable } from "@litbase/use-observable";
import {
  currentLanguageSettings$,
  currentTranslations$,
  getCurrentTranslations,
  getLanguageSettings,
  getTranslation,
} from "../utils/translate";
import { TranslationInput } from "../utils/translation-interface";

export function useTranslation(value: TranslationInput) {
  const translations = useTranslations();
  const settings = useLanguageSettings();

  return getTranslation(value, translations, settings);
}

export function useTranslations() {
  const [translationTable] = useObservable(() => currentTranslations$, getCurrentTranslations(), []);
  return translationTable;
}

export function useLanguageSettings() {
  const [settings] = useObservable(() => currentLanguageSettings$, getLanguageSettings());
  return settings;
}

export function useLanguage() {
  const { language } = useLanguageSettings();
  return language;
}

export function useTranslate() {
  const translations = useTranslations();
  const settings = useLanguageSettings();

  return useCallback(
    (value: TranslationInput) => getTranslation(value, translations, settings),
    [translations, settings]
  );
}

export interface TranslateProps {
  children?: TranslationInput;

  [key: string]: unknown;
}

const devModeEnabled = typeof window !== "undefined" && !!localStorage.getItem("translationDevMode");

function TranslateComponent({ children }: TranslateProps) {
  const translation = useTranslation(children);

  if (devModeEnabled) {
    return <span title={String(children)}>{translation}</span>;
  }

  return translation as ReactNode;
}

export const Translate = memo(TranslateComponent as FunctionComponent<TranslateProps>);
export const T = Translate;
