import { config } from "@litbase/client";

export function startLitbaseClient() {
  if (!process.env.SERVER_BASE_URL) throw new Error("SERVER_BASE_URL is not defined");

  config.debug = true; // Use debug mode for helpful debug messages
  config.url = getWebsocketUrl(process.env.SERVER_BASE_URL);

  // Specify any custom fields saved on users
  config.currentUserFields = {
    isAdmin: 1,
  };

  // We don't need to connect as we only use http calls
}

function getWebsocketUrl(httpUrl: string) {
  const url = new URL(httpUrl);

  if (url.protocol === "http:") {
    url.protocol = "ws:";
  } else {
    url.protocol = "wss:";
  }

  url.pathname = "/ws";
  return url.toString();
}
