import { BehaviorSubject, combineLatest, from, of, shareReplay, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { isPlainObject } from "@litbase/core";
import { merge } from "lodash-es";
import { LanguageSettings, TranslationEntry, TranslationInput, TranslationTable } from "./translation-interface";

const defaultLanguageSettings: LanguageSettings = {
  language: "hu",
  fallbackLanguage: "hu",
  forcePlaceholders: false,
  disableFallback: false,
  translationLoader: (code) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const contents = require(`../translations/${code}.json`);
    return Object.fromEntries(Object.entries(contents));
  },
};

export const currentLanguageSettings$ = new BehaviorSubject(defaultLanguageSettings);

let currentTranslations: TranslationTable = {};
export const currentTranslations$ = currentLanguageSettings$.pipe(
  switchMap(({ fallbackLanguage, forcePlaceholders, disableFallback, language, translationLoader }) => {
    if (forcePlaceholders) return of({});

    const translationsObject = translationLoader(language);
    const translations$ = isPlainObject(translationsObject) ? of(translationsObject) : from(translationsObject);

    if (!disableFallback && language !== fallbackLanguage) {
      const fallbackTranslationsObject = translationLoader(fallbackLanguage);

      return combineLatest([
        translations$,
        isPlainObject(fallbackTranslationsObject) ? of(fallbackTranslationsObject) : from(fallbackTranslationsObject),
      ]).pipe(
        map(
          ([translations, fallbackTranslations]) =>
            merge({}, fallbackTranslations || {}, translations || {}) as TranslationTable
        )
      );
    }

    return translations$;
  }),
  shareReplay({ bufferSize: 1, refCount: false })
);

currentTranslations$.subscribe((translations) => {
  currentTranslations = translations;
});

export function setLanguageSettings(settings: Partial<LanguageSettings>) {
  currentLanguageSettings$.next({ ...getLanguageSettings(), ...settings });
}

export function getLanguageSettings() {
  return currentLanguageSettings$.getValue();
}

export function getCurrentTranslations() {
  return currentTranslations;
}

export function getTranslation(
  value: TranslationInput,
  translationTable = getCurrentTranslations(),
  { language, fallbackLanguage } = getLanguageSettings()
): string {
  if (!value) return "";

  let translation: TranslationEntry;
  if (typeof value === "string") {
    translation = getIn(value.trim(), translationTable) as unknown as string | undefined;
  } else if (typeof value === "object") {
    translation = value[language] || value[fallbackLanguage];
  }

  return null != translation ? String(translation) : `__${value}__`;
}

function getIn(path: string, obj: Record<string, unknown>) {
  const explodedPath = path.split(".");

  let currentParent: Record<string, unknown> = obj;

  for (const segment of explodedPath) {
    const newParent = currentParent[segment];

    if (null == newParent) return null;

    currentParent = newParent as Record<string, unknown>;
  }

  return currentParent;
}
